























































































































































































































@import '~@/assets/styles/helpers/_variables.scss';

.v-card { border-radius: 16px !important; }
